import 'classlist-polyfill';
import objectFitImages from 'object-fit-images';

import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
import floatingLabels from './components/forms/floating-labels';
import autosize from './components/forms/autosize';
// import cookieNotification from './components/cookies/cookieNotification';
import ModalManager from './components/modals/ModalManager';
import fadeInElements from './components/layout/fade-in-elements';

import Contact from './pages/Contact';

require('./utils/nativeConsole');

// eslint-disable-next-line
window.modalManager = new ModalManager();
// Layout setup
responsiveVideos();
floatingLabels();
autosize();
fadeInElements();
objectFitImages();
// cookieNotification();

// Forms
validation();

// Page specific classes
const pages = {
  Contact,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName](); // eslint-disable-line no-new
  }
}

window.modalManager.checkModalOnPageLoad();

// Set js class
document.documentElement.classList.add('js');
document.documentElement.classList.remove('no-js');
