import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const fadeInEl = elements => {
  gsap.fromTo(
    elements,
    { y: 5, alpha: 0 },
    {
      duration: 0.9,
      y: 0,
      alpha: 1,
      ease: 'power2.inOut',
      stagger: 0.15,
      once: true,
    },
  );
};

export default function fadeInElements() {
  ScrollTrigger.batch('.js-fade-in', {
    onEnter: elements => {
      fadeInEl(elements);
    },
    start: 'top 95%',
    once: true,
  });
}
